import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Dashboard',
        icon: 'bx-home-circle' ,
        link: '/'
    },    
    {
        id: 2,
        label: 'Resellers',
        icon: 'bx-user-circle' ,
        link: '/reseller'
    },
    {
        id: 14,
        label: 'Clients',
        icon: 'bx-user' ,
        link: '/clients'
    },
    {
        id: 16,
        label: 'Affiliates',
        icon: 'bx-user' ,
        link: '/affiliates'
    },
    {
        id: 3,
        label: 'Services',
        icon: 'bx bx-git-branch' ,
        link: '/services'
    },
    {
        id: 4,
        label: 'Wallet History',
        icon: 'bx-credit-card',
        subItems: [
            {
                id: 5,
                label: 'Reseller Wallet History',
                link: '/reseller-wallet-history',
                parentId: 4
            },
            {
                id: 6,
                label: 'Client Wallet History',
                link: '/client-wallet-history',
                parentId: 4
            }
        ]
    },
    {
        id: 7,
        label: 'Order History',
        icon: 'bx-file',
        subItems: [
            {
                id: 8,
                label: 'Reseller Order History',
                link: '/order-history/reseller-order-history',
                parentId: 7
            },
            {
                id: 9,
                label: 'Client Order History',
                link: '/order-history/client-order-history',
                parentId: 7
            }
        ]
    },
    {
        id: 10,
        label: 'Wallet Balance',
        icon: 'bx-wallet',
        subItems: [
            {
                id: 11,
                label: 'Reseller Wallet Balance',
                link: '/wallet-balance/reseller-wallet-balance',
                parentId: 7
            },
            {
                id: 12,
                label: 'Client E-Wallet Balance',
                link: '/wallet-balance/client-wallet-balance',
                parentId: 7
            },
            {
                id: 13,
                label: 'Client C-Wallet Balance',
                link: '/wallet-balance/client-cwallet-balance',
                parentId: 7
            }
        ]
    },
    {
        id: 15,
        label: 'FAQ',
        icon: 'bx bx-question-mark',
        link: '/faq'
    }

];

